import request from "@/utils/request";


// 前端事件设置后获取数据

export function getFrontNdEventList(data) {
    return request({
      url: "/api/formRequest/getData",
      method: "post",
      data,
    //  baseURL:"/Api2"
    });
  }


// 保存数据
  export function getFormRequest(data) {
    return request({
      url: "/api/formRequest/insert",
      method: "post",
      data,
      //  baseURL:"/Api2"
    });
  }

  //前端事件回显
  export function getConfig(data) {
    return request({
      url: "/api/formRequest/getConfig",
      method: "post",
      data,
      //  baseURL:"/Api2"
    });
  }
