<template>
  <div class="moduleScreen">
    <my-input
      style="width: 90%"
      :data="moduleList"
      :index="index"
      :draftData="draftData"
      v-if="moduleList.__config__.tag == 'el-input'"
      @tableValue="handleValue"
    ></my-input>

    <my-input-number
      :data="moduleList"
      :index="index"
      :draftData="draftData"
      v-else-if="moduleList.__config__.tag == 'inputNumber'"
      @tableValue="handleValue"
    ></my-input-number>

    <my-select
      :data="moduleList"
      :draftData="draftData"
      :index="index"
      v-else-if="moduleList.__config__.tag == 'el-select'"
      @tableValue="handleValue"
    ></my-select>

    <my-cascader
      :index="index"
      :data="moduleList"
      :draftData="draftData"
      v-else-if="moduleList.__config__.tag == 'el-cascader'"
      @tableValue="handleValue"
    ></my-cascader>

    <my-radio
      :data="moduleList"
      :index="index"
      :draftData="draftData"
      v-else-if="
        moduleList.__config__.tag == 'el-radio-group' ||
        moduleList.__config__.tag == 'el-checkbox-group'
      "
      @tableValue="handleValue"
    ></my-radio>

    <my-time
      :data="moduleList"
      :index="index"
      :draftData="draftData"
      v-else-if="moduleList.__config__.tag == 'datePicker'"
      @tableValue="handleValue"
    ></my-time>

    <my-upload
      :index="index"
      :data="moduleList"
      v-else-if="moduleList.__config__.tag == 'el-upload'"
      @tableValue="handleValue"
      :formId="formId"
      :appId="appId"
      :draftData="draftData"
    ></my-upload>
    <my-addr
      :data="moduleList"
      :index="index"
      :draftData="draftData"
      v-else-if="moduleList.__config__.tag == 'addr'"
      @tableValue="handleValue"
    ></my-addr>
    <my-depart
      :index="index"
      :data="moduleList"
      :draftData="draftData"
      v-else-if="moduleList.__config__.tag == 'departCheck'"
      @tableValue="handleValue"
    ></my-depart>
    <my-menber
      :data="moduleList"
      :index="index"
      :draftData="draftData"
      v-else-if="moduleList.__config__.tag == 'menberCheck'"
      @tableValue="handleValue"
    ></my-menber>
    <my-sign
      :data="moduleList"
      @tableValue="handleValue"
      :index="index"
      v-else-if="moduleList.__config__.tagIcon == 'Sign'"
    ></my-sign>
    <my-position
      :index="index"
      :data="moduleList"
      @tableValue="handleValue"
      v-else-if="moduleList.__config__.tagIcon == 'Position'"
    ></my-position>
    <my-depart-single
      :index="index"
      :data="moduleList"
      :draftData="draftData"
      v-else-if="moduleList.__config__.tag == 'departSingle'"
      @tableValue="handleValue"
    ></my-depart-single>
    <my-menber-single
      :data="moduleList"
      :index="index"
      :draftData="draftData"
      v-else-if="moduleList.__config__.tag == 'menberSingle'"
      @tableValue="handleValue"
    ></my-menber-single>
  </div>
</template>

<script>
import myInput from "./myInput.vue";
import myInputNumber from "./myInputNumber.vue";
import mySelect from "./mySelect.vue";
import myCascader from "./myCascader.vue";
import myRadio from "./myRadio.vue";
import myTime from "./myTime.vue";
import myUpload from "./myUpload.vue";
import myAddr from "./myAddr.vue";
import myDepart from "./myDepart.vue";
import myMenber from "./myMenber.vue";
import mySign from "./mySign.vue";
import myPosition from "./myPostion.vue";
import myDepartSingle from "./myDepartSingle.vue";
import myMenberSingle from "./myMenberSingle.vue";
export default {
  props: {
    moduleList: {
      type: Object,
      required: true,
    },
    formId: {
      type: String,
    },
    appId: {
      type: String,
    },
    index: {
      type: Number,
    },
    draftData: {
      type: Object,
    },
  },

  name: "ModuleScreen",
  data() {
    return {
      formData: {},
    };
  },
  components: {
    myInput,
    myInputNumber,
    mySelect,
    myCascader,
    myRadio,
    myAddr,
    myTime,
    myDepart,
    myUpload,
    myMenber,
    mySign,
    myPosition,
    myDepartSingle,
    myMenberSingle,
  },
  created() {},
  methods: {
    handleValue(val) {
      let query = val;
      query.isTable = true;
      this.$emit("tableValue", query);
    },
  },
};
</script>

<style scoped>
.el-rate {
  display: inline-block;
}
</style>
