import { render, staticRenderFns } from "./myDepartSingle.vue?vue&type=template&id=4e8e6428&scoped=true"
import script from "./myDepartSingle.vue?vue&type=script&lang=js"
export * from "./myDepartSingle.vue?vue&type=script&lang=js"
import style0 from "./myDepartSingle.vue?vue&type=style&index=0&id=4e8e6428&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e8e6428",
  null
  
)

export default component.exports